import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import parse from 'html-react-parser';
import SecutiryImage from '../security.jpg';

import { Checkbox, FormGroup, FormControlLabel, Container } from '@mui/material';

import config from '../config';

const CgvPage = () => {
  const [cgv, setCgv] = useState(false);
  const [game, setGame] = useState(false);
  const [uuid, setUuid] = useState('');
  const [cgvText, setCgvText] = useState('');
  const [message, setMessage] = useState('');
  const [isError, setIsError] = useState(false);

  let location = useLocation();

  useEffect(() =>{
    const parsed = queryString.parse(location.search);
    const { space , uuid } = parsed;
    setUuid(uuid as string);
 
    fetch(`${config("MODEL_API_URL")}/cgv-space/${space}`).then(async (res: any) => {
      const response = await res.json(); 
      const { cgv } = response;
      if(cgv){
        setCgvText(cgv);
      }
    })
  },[])

  const handleSubmit = (event: any) => {
    event.preventDefault();
    setIsError(false);
    setMessage('');
    var userAgent = navigator.userAgent;
    if(cgv && game){
      fetch(`${config("ACCOUNTS_API_URL")}/validate-cgv/${uuid}`,{ method : "POST" }).then(async (res: any) => {
        if(res.status === 200){
          if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(userAgent)){
            window.location.replace('https://starbolt-fleetview.eu/gotoapp')
          }
          else {
            setMessage('Validation réussi ! Vous pouvez vous connecter sur l\'application');
          }
        } else {
          setMessage('Une erreur est survenue');
          setIsError(true);
        }
      }).catch((error) => {
          setMessage('Une erreur est survenue');
          setIsError(true);
      })
    }
  }

  return (
    <Background>
      <SecurityImg 
        src={SecutiryImage}
      />
      <Title>
        <Img
          src="http://cdn.mcauto-images-production.sendgrid.net/7a5361a29cf33d00/967921ce-dade-4754-aaf4-1bf844bb5e37/500x79.png"
        />
      </Title>
      <CustomizedContainer>
        <form onSubmit={handleSubmit}>
        <Cgv>
         {parse(cgvText)}
        </Cgv>
        <Validation>
          <FormGroup>
            <CustomFormControlLabel 
              control={
                <Checkbox 
                  sx={{
                    color :  "#ffe604 !important", 
                  }} 
                  onChange={() => setCgv(!cgv)}
                  checked={cgv} 
                  required
                />
              } 
              label="Je valide les conditions générales de ventes" 
              sx={{color : "#ffe604", margin : 0}}
            />
            <ContainerVideoLink>
              <Text>Veuillez regarder la vidéo sur la sécurité routière qui se trouve <Link href={`${config("KAHOOT_LINK")}`} target="_blank" >ici !</Link></Text>
            </ContainerVideoLink>
            <CustomFormControlLabel 
              control={
                <Checkbox 
                  sx={{color :  "#ffe604 !important"}} 
                  onChange={() => setGame(!game)}
                  checked={game}
                  required 
                />
              } 
              label="Je valide avoir fait la formation sécurité routière" 
              sx={{color : "#ffe604", margin : 0}}
            />
          </FormGroup>
          <Submit>
            <Input type="submit" value="Valider" />
            <Message style={{color : isError ? 'red' : '#ffe604'}} >{message}</Message>
          </Submit>
        </Validation>
      </form>
    </CustomizedContainer>
  </Background>
  );
}

export default CgvPage;

const Background = styled.div`
  width         : 100%;
  height        : 100%;
  display       : flex;
  flex-direction: column;
`;

const Title = styled.div`
  flex           : 1;
  display        : flex;
  flex-direction : row;
  justify-content: center;
  margin-top     : 1rem;
`;

const Img = styled.img`
  width : 20rem;

  @media screen and (max-width : 500px){
    width : 10rem;
  }
`;

const SecurityImg = styled.img`
  width : 100%;
  height: 35rem;
  object-fit : contain;
  @media screen and (max-width : 1000px){
    object-fit : contain;
    height: 30%;
  }
`;  

const Cgv = styled.div`
  flex            : 1;
  max-height      : 20rem;
  overflow-y      : scroll;
  color           : #ffe604;
  background-color: #272744;
  margin-top      : 3rem;
  margin-bottom   : 10px;
  padding         : 2rem;

  @media screen and (max-width : 500px){
    padding    : 1rem;
    margin-top : 3rem;
  }

  ::-webkit-scrollbar {
    width: 1em;
  }
   
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius : 10px;
  }
   
  ::-webkit-scrollbar-thumb {
    background-color: #ffe604;
    outline: 1px solid slategrey;
    border-radius : 10px;
  }

`;

const Validation = styled.div`
  
`;

const Text = styled.div`
  margin-top   : 15px;
  margin-bottom: 5px;
  color        : #ffe604;
  margin-right : 10px;
  font-weight  : 600;

  @media screen and (max-width : 500px){
    font-size : 12px;
  }
`;

const Link = styled.a`
  color : #ffe604;
`;

const CustomizedContainer = styled(Container)`
  padding-bottom : 20px;
`;

const CustomFormControlLabel = styled(FormControlLabel)`
  .MuiFormControlLabel-label {
    @media screen and (max-width : 500px){
      font-size : 12px;
    }    
  }
`;

const Input = styled.input`
  background-color: #ffe604;
  border          : none;
  border-radius   : 2px;
  width           : 200px;
  height          : 30px;
  font-size       : 16px;
`;

const Message = styled.div`
  height    : 10px;
  font-size : 12px;
  margin-top: 10px;
`;

const Submit = styled.div`
  display        : flex;
  flex-direction : column;
  align-items    : center;
  justify-content: center;
  width          : 100%;
  margin-top     : 15px;
`;

const ContainerVideoLink = styled.div`
  display       : flex;
  flex-direction: column;
`;