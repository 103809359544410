import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import CgvPage from './pages/Cgv';

import './App.css';

const router = createBrowserRouter([
  {
    path: "*",
    element: <CgvPage />,
  },
  {
    path: "/cgv",
    element: <CgvPage />,
  },
]);

function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;